<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-5">
      <div class="z-10 flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-7">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Instruments</span>
        </div>
        <div class="flex space-x-5 justify-center sm:justify-start">
          <div class="pt-1.5">
            <div
              @click="$router.push({ name: 'certificates.training', query: { page: 1 } })"
              class="py-2 px-6 rounded-lg border border-hdarkblue capitalize font-Roboto text-sm text-center text-hdarkblue hover:bg-hdarkblue hover:text-white cursor-pointer"
            >
              Training Certificate List
            </div>
          </div>
          <div class="pt-1.5">
            <div
              @click="$router.push({ name: 'certificates', query: { limit: 4, offset: 0 } })"
              class="py-2 px-6 rounded-lg border border-hdarkblue capitalize font-Roboto text-sm text-center text-hdarkblue hover:bg-hdarkblue hover:text-white cursor-pointer"
            >
              Instrument Certificate List
            </div>
          </div>
          <div class="pt-1.5">
            <div
              @click="$router.push({ name: 'caf', query: { page: 1 } })"
              class="py-2 px-6 rounded-lg border border-hdarkblue capitalize font-Roboto text-sm text-center text-hdarkblue hover:bg-hdarkblue hover:text-white cursor-pointer"
            >
              CAF List
            </div>
          </div>
        </div>
      </div>
      <!-- ... -->
      <div class="z-10">
        <div class="grid sm:grid-cols-3 gap-4 justify-center">
          <div class="instrument-card relative">
            <!--  -->
            <div>
              <div class="flex">
                <IconInstrument class="w-8 h-8 mr-3 mt-0.1" />
                <p class="text-2xl font-medium">Instruments</p>
              </div>
              <div class="h-0 w-full mt-2.5 mb-4" style="border-bottom: 2px solid #a3a3a34d"></div>

              <div class="grid gap-3.5" v-if="user.instruments">
                <div
                  class="relative instrument-card-list flex cursor-pointer hover:bg-hgray6"
                  v-for="d in user.instruments.data"
                  :key="d.id"
                  @click="openMenu(d)"
                >
                  <div class="w-3 bg-hblue h-full mr-3" style="border-radius: 6px 0 0 6px"></div>
                  <div class="py-2 pr-2">
                    <div class="font-semibold text-sm">SN: {{ d.serial_number }}</div>
                    <div class="font-medium text-xs">{{ d.contract_type }} - {{ d.type }}</div>
                  </div>
                </div>
              </div>

              <img v-else :src="NoInstrument" class="my-32 mx-auto" />
            </div>
          </div>
          <div class="instrument-card relative">
            <!--  -->
            <div>
              <div class="flex">
                <IconInterface class="w-8 h-8 mr-3 mt-0.1" />
                <p class="text-2xl font-medium">Interface</p>
              </div>
              <div class="h-0 w-full mt-2.5 mb-4" style="border-bottom: 2px solid #a3a3a34d"></div>

              <div class="grid gap-3.5" v-if="user.interfaces">
                <div
                  class="relative instrument-card-list flex"
                  v-for="d in user.interfaces.data"
                  :key="d.id"
                >
                  <div class="w-3 bg-hgreen h-full mr-3" style="border-radius: 6px 0 0 6px"></div>
                  <div class="py-2 pr-2">
                    <div class="font-semibold text-sm">{{ d.code }}</div>
                    <!-- <div class="font-medium text-xs">Sold - Flexavision SF Double Tube ·</div> -->
                  </div>
                </div>
              </div>

              <img v-else :src="NoInterface" class="mx-auto my-32" />
            </div>
          </div>
          <div class="instrument-card relative">
            <!--  -->
            <div>
              <div class="flex">
                <IconModule class="w-8 h-8 mr-3 mt-0.1" />
                <p class="text-2xl font-medium">Modules</p>
              </div>
              <div class="h-0 w-full mt-2.5 mb-4" style="border-bottom: 2px solid #a3a3a34d"></div>

              <div class="grid gap-3.5" v-if="user.modules">
                <div
                  class="relative instrument-card-list flex"
                  v-for="d in user.modules.data"
                  :key="d.id"
                >
                  <div class="w-3 bg-horange h-full mr-3" style="border-radius: 6px 0 0 6px"></div>
                  <div class="py-2 pr-2">
                    <div class="font-semibold text-sm">
                      {{ d.version }}
                    </div>
                    <!-- <div class="font-medium text-xs">Sold - Flexavision SF Double Tube ·</div> -->
                  </div>
                </div>
              </div>

              <img v-else :src="NoModule" class="mx-auto my-32" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconInstrument from '@/assets/icons/icon-instrument-round.svg?inline'
import IconInterface from '@/assets/icons/icon-interface-round.svg?inline'
import IconModule from '@/assets/icons/icon-modules-round.svg?inline'

import NoInstrument from '@/assets/images/no-instrument.png'
import NoInterface from '@/assets/images/no-interface.png'
import NoModule from '@/assets/images/no-modules.png'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'instruments-index',
  components: {
    IconInstrument,
    IconInterface,
    IconModule,
    IconArrow
  },
  data() {
    return {
      NoInstrument,
      NoInterface,
      NoModule
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    ...mapActions('modal', ['MODAL_INSTRUMENT']),
    openMenu(val) {
      this.MODAL_INSTRUMENT(val)
    }
  }
}
</script>
